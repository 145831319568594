export default function ({ app, route, redirect }) {
  app.$auth.onRedirect((to, from) => {
    
    // When logging in
    if (to === app.$auth.options.redirect.home) {
      const redirectUrl = app.$auth.$storage.getUniversal('redirect');
      // Clear the stored URL
      app.$auth.$storage.setUniversal('redirect', null);
      
      // If we have a stored URL, use it instead of default home
      // if (redirectUrl) {
      //   // Ensure the URL is a full path, not just a fragment
      //   return redirectUrl.startsWith('/') ? redirectUrl : `/${redirectUrl}`;
      // }
    }
    // return to;
  });

  // Store the current path before redirecting to login
  app.$auth.onError((error) => {
    if (error.status === 401 || error.status === 403) {
      // Store the current path before redirecting
      const currentPath = route.fullPath;
      app.$auth.$storage.setUniversal('redirect', currentPath);
    }
  });
}