export class ProductHelpers {
  constructor(http, context, lang = 2) {
    this.context = context;
    this.http = http;
    this.languageIsoCode = lang;
    if (lang === 'fr') {
      this.lang = 1;
    } else if (lang === 'de') {
      this.lang = 3;
    } else {
      this.lang = 2;
    }
  }

  async getCategories() {
    const response = await this.http.API.get(
      `menu?LanguageIsoCode=${this.languageIsoCode}`
    );

    if (response.status === 200) {
      const categories = response.data;
      return categories;
    } else {
      throw new Error(`Unexpected status code: ${response.status}`);
    }
  }

  async getProduct(permalink) {
    const words = permalink.split('-');
    const id = words[words.length - 1];
    try {
      const { data } = await this.http.API.get(`products/${id}`);
      const product = data[0];
      return product;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async getProductsWithListIds({ IdProductList, LanguageIsoCode }) {
    try {
      const response = await this.http.API.get(
        `lookbook/products?IdProductList=${IdProductList}&LanguageIsoCode=${LanguageIsoCode}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  getProductstSimilar(permalink) {
    const words = String(permalink).split('-');
    const id = words[words.length - 1];

    const productSimilarResponse = this.http.API.get(
      `productRecommendation?IdProduct=${id}&NumberOfProducts=10`
    );
    // const productsSimilar = productSimilarResponse.data;
    return productSimilarResponse;
  }

  async getListByCategory({
    permalink,
    page,
    _limit,
    filterData,
    idCategory,
    sortby,
  }) {
    let local = 'fr';
    if (this.lang === 2) {
      local = 'en';
    } else if (this.lang === 3) {
      local = 'de';
    }
    const link = `${permalink}`;

    try {
      const limit = (page - 1) * _limit;
      const offset = page * _limit - 1;

      let category = '';
      if (permalink) {
        category = `CategoryDescriptionFriendlyURL=${link}`;
      } else {
        category = `IdCategory=${idCategory}`;
      }

      let sort = '';
      if (sortby) {
        if (sortby.sortby !== 'Default') {
          sort = `&${sortby.sortby}=${sortby.value}`;
        }
      }

      let url = `category?${category}&IdLang=${this.lang}&FromProductNumber=${limit}&ToProductNumber=${offset}${sort}`;

      if (filterData) {
        const filterIdAttributes = filterData.FilterIdAttributes
          ? `&FilterIdAttributes=${filterData.FilterIdAttributes}`
          : '';
        const filterMinPriceTaxExcl = filterData.FilterMinPriceTaxExcl
          ? `&FilterMinPriceTaxExcl=${filterData.FilterMinPriceTaxExcl}`
          : '';
        const filterMaxPriceTaxExcl = filterData.FilterMaxPriceTaxExcl
          ? `&FilterMaxPriceTaxExcl=${filterData.FilterMaxPriceTaxExcl}`
          : '';

        url += `${filterIdAttributes}${filterMinPriceTaxExcl}${filterMaxPriceTaxExcl}`;
      }

      const data = await this.http.API.$get(url);

      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  //new
  async getListByCategory2({
    permalink,
    page,
    idCategory,
    filterData,
    _limit,
    sortby,
  }) {
    _limit = _limit
      ? _limit
      : this.context.$GLOBAL_VARIABLES.categoryNuberPerPage;

    let local = 'fr';
    if (this.lang === 2) {
      local = 'en';
    } else if (this.lang === 3) {
      local = 'de';
    }
    const link = `${permalink}`;

    try {
      const limit = (page - 1) * _limit;
      const offset = page * _limit;

      let category = '';
      if (permalink) {
        category = `CategoryDescriptionFriendlyURL=${link}`;
      } else {
        category = `IdCategory=${idCategory}`;
      }

      let sort = '';
      if (sortby) {
        if (sortby.sortby !== 'Default') {
          sort = `&${sortby.sortby}=${sortby.value}`;
        }
      }

      let url = `category?${category}&IdLang=${this.lang}&FromProductNumber=${limit}&ToProductNumber=${offset}${sort}`;

      if (filterData) {
        const filterIdAttributes = filterData.filterIdAttributes
          ? `&FilterIdAttributes=${filterData.filterIdAttributes}`
          : '';
        const filterMinPriceTaxExcl = filterData.filterMinPriceTaxExcl
          ? `&FilterMinPriceTaxExcl=${filterData.filterMinPriceTaxExcl}`
          : '';
        const filterMaxPriceTaxExcl = filterData.filterMaxPriceTaxExcl
          ? `&FilterMaxPriceTaxExcl=${filterData.filterMaxPriceTaxExcl}`
          : '';

        url += `${filterIdAttributes}${filterMinPriceTaxExcl}${filterMaxPriceTaxExcl}`;
      }

      const data = await this.http.API.get(url);

      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  // Disponibilité d'un produit en boutiques par IdProduct et IdProductAttribute

  async reservationShopAvailability(idProduct, IdProductAttribute) {
    try {
      const data = await this.http.API.$get(
        `store/reservation/product/stock?IdProduct=${idProduct}&IdProductAttribute=${IdProductAttribute}`
      );
      return data;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async reservationProduct({ customer, product, shop, reservation }) {
    let price = product?.Price?.RegularPrice;

    if (price?.PromotionalPrice) {
      price = price?.PromotionalPrice;
    }

    var FormData = require('form-data');
    var formData = new FormData();

    formData.append('IdStore', shop.IdStore);
    formData.append('IdGender', customer.civility);
    formData.append('Email', customer.email);
    formData.append('Firstname', customer.firstName);
    formData.append('Lastname', customer.lastName);
    formData.append('MobilePhone', customer.phone);
    formData.append('IdProduct', product.IdProduct);
    formData.append('IdProductAttribute', reservation.idProductAttribute);
    formData.append('Quantity', reservation.quantity);
    formData.append('PriceTaxIncl', price.PriceTaxIncl);
    formData.append('PriceTaxExcl', price.PriceTaxExcl);

    let httpApi = this.http.API;
    if (this.context.$auth.loggedIn) {
      httpApi = this.http.API_AUTH;
    }

    try {
      const response = await httpApi.post(
        `store/reservation/product/reserve`,
        formData
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async productAvailability({ productId, LanguageIsoCode }) {
    try {
      const res = await this.http.API.get(
        `product/${productId}/variant?LanguageIsoCode=${LanguageIsoCode}`
      );
      if (res.status === 200) {
        const { data } = res;
        return data;
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  //v2 -------------------

  async category({ permalink, idCategory, languageIsoCode, token }) {
    let cat = permalink
      ? `?CategoryDescriptionFriendlyURL=${permalink}`
      : `?IdCategory=${idCategory}`;

    if (token) {
      cat += `&token=${token}`;
    }
    const response = await this.http.API.get(
      `category${cat}&LanguageIsoCode=${languageIsoCode}`
    );

    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw new Error(`Unexpected status code: ${response.status}`);
    }
  }
  async categoryProducts({
    idCategory = null,
    permalink,
    languageIsoCode,
    currencyIsoCode,
    limit = 29,
    offset = 0,
    filterAttribute = null,
    prices = null,
    promotion,
    orderBy,
    token,
  }) {
    let cat = permalink
      ? `?CategoryDescriptionFriendlyURL=${permalink}`
      : `?IdCategory=${idCategory}`;
    let LanguageIsoCode = `&LanguageIsoCode=${languageIsoCode}`;
    let CurrencyIsoCode = `&CurrencyIsoCode=${currencyIsoCode}`;
    let Offset = `&Offset=${offset}`;
    let Limit = `&Limit=${limit}`;
    let FilterAttribute = filterAttribute
      ? `&FilterAttribute=${filterAttribute}`
      : '';
    let FilterPrice = prices ? `&FilterPrice=${prices}` : '';
    let Promotion = promotion ? `&InPromotion=${promotion}` : '';
    let Token = token ? `&token=${token}` : '';

    let SortBy = '';
    if (orderBy) {
      if (orderBy.sortby === 'SortByPrice') {
        SortBy = `&SortByPrice=${orderBy.value}`;
      } else if (orderBy.sortby === 'SortByNewest') {
        SortBy = `&SortByNewest=${orderBy.value}`;
      }
    }

    const response = await this.http.API.get(
      `category/product${cat}${LanguageIsoCode}${CurrencyIsoCode}${Offset}${Limit}${FilterAttribute}${FilterPrice}${SortBy}${Token}${Promotion}`
    );

    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw new Error(`Unexpected status code: ${response.status}`);
    }
  }

  async productsAssociation({ idProduct, languageIsoCode, currencyIsoCode }) {
    let query = `?LanguageIsoCode=${languageIsoCode}`;
    query += `&CurrencyIsoCode=${currencyIsoCode}`;

    const response = await this.http.API.get(
      `product/${idProduct}/association${query}`
    );

    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw new Error(`Unexpected status code: ${response.status}`);
    }
  }

  async categoryFilterOptions({
    idCategory,
    permalink,
    languageIsoCode,
    currencyIsoCode,
  }) {
    let cat = permalink
      ? `?CategoryDescriptionFriendlyURL=${permalink}`
      : `?IdCategory=${idCategory}`;
    const response = await this.http.API.get(
      `category/filter${cat}&CurrencyIsoCode=${currencyIsoCode}&LanguageIsoCode=${languageIsoCode}`
    );

    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw new Error(`Unexpected status code: ${response.status}`);
    }
  }

  //product
  async getProductV2({ permalink, languageIsoCode, currencyIsoCode, token }) {
    permalink = String(permalink);
    const words = permalink.split('-');
    const id = words[words.length - 1];
    let url = `product/${id}?LanguageIsoCode=${languageIsoCode}&CurrencyIsoCode=${currencyIsoCode}`;
    try {
      if (token) {
        url += `&token=${token}`;
      }
      const data = await this.http.API.get(url);

      return data;
    } catch (error) {
      return error;
    }
  }
  async getProductV2Variant({ idProduct, languageIsoCode }) {
    try {
      const response = await this.http.API.get(
        `product/${idProduct}/variant?LanguageIsoCode=${languageIsoCode}`
      );
      if (response.status === 200) {
        const { data } = response;
        return data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(`${error.message}`);
    }
  }
}
